import React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}
function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };
    const handleMouseLeave = () => {
        setShowFullCell(false);
    };
    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }
        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
                setShowFullCell(false);
            }
        }
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);
    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: "center",
                lineHeight: "24px",
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: "100%",
                    width,
                    display: "block",
                    position: "absolute",
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});
function renderCellExpand(params) {
    return (
        <GridCellExpand
            value={params.formattedValue || ""}
            width={displayTextWidth(
                params.formattedValue,
                ".9rem Proxima Nova Rg"
            )}
        />
    );
}
function displayTextWidth(text, font) {
    let canvas =
        displayTextWidth.canvas ||
        (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 30;
}
export default function Basic(props) {
    let userAttributes = [];
    let i = 0;
    let columns = [];
    let rows = [];

    const dateFormatter = new Intl.DateTimeFormat("en-US");
    if (
        props.unitSubscriptionsList !== undefined
        // &&
        // props.venueListUtilization !== undefined
    ) {
        for (const key of Object.keys(props.unitSubscriptionsList)) {
            //  if (
            //   props.venueListUtilization[props.venueList[key]["VenueID"]] !==
            //   undefined
            // ) {
            if (props.unitSubscriptionsList !== undefined) {
                userAttributes.push({
                    id: key,
                    subNameFirst:
                        props.unitSubscriptionsList[key]["SubscriberGivenName"],
                    subNameLast:
                        props.unitSubscriptionsList[key][
                            "SubscriberFamilyName"
                        ],
                    subAddress1:
                        props.unitSubscriptionsList[key]["AddressLine1"],
                    subAddress2:
                        props.unitSubscriptionsList[key]["AddressLine2"],
                    subCity: props.unitSubscriptionsList[key]["City"],
                    subState: props.unitSubscriptionsList[key]["State"],
                    subPostalCode:
                        props.unitSubscriptionsList[key]["PostalCode"],
                    subPhone:
                        props.unitSubscriptionsList[key][
                            "SubscriberPhoneNumber.E164"
                        ],
                    subEmail:
                        props.unitSubscriptionsList[key]["SubscriberEmail"],
                    subInService: props.unitSubscriptionsList[key]["Status"],
                    subStartDate:
                        props.unitSubscriptionsList[key][
                            "StartDateTimeUTC.ISO8601"
                        ],
                    subUnitName:
                        "Unit: " +
                        props.unitSubscriptionsList[key]["UnitID"] +
                        ", Building: " +
                        props.unitSubscriptionsList[key]["UnitBuilding"] +
                        ", Floor: " +
                        props.unitSubscriptionsList[key]["UnitFloor"],
                });
                i = i + 1;
            }
        }
    }
    columns = [
        {
            headerName: "Subscriber Name",
            field: "subName",
            minWidth: 180,
            renderCell: (params) =>
                params.row.subNameFirst + " " + params.row.subNameLast,
        },
        {
            headerName: "Address",
            field: "address",
            minWidth: 200,
            renderCell: (params) => (
                // console.log(params)
                <div>
                    <div>{params.row.subAddress1}</div>
                    <div>{params.row.subAddress2}</div>
                    <div>
                        {params.row.subCity}, {params.row.subState}{" "}
                        {params.row.subPostalCode}
                    </div>
                </div>
            ),
        },
        {
            headerName: "Phone",
            field: "subPhone",
            minWidth: 120,
        },
        {
            headerName: "Email",
            field: "subEmail",
            minWidth: 120,
        },
        {
            headerName: "Service Status",
            field: "subInService",
            minWidth: 150,
        },
        {
            headerName: "Start Date",
            field: "subStartDate",
            type: "date",
            valueFormatter: (value) =>
                value &&
                new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }).format(new Date(value)),
            minWidth: 150,
        },
    ];
    rows = userAttributes;
    return (
        <div style={{ height: 400, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                hideFooterPagination={false}
                hideFooter={false}
                getRowHeight={({ id, densityFactor }) => {
                    return 60 * densityFactor;
                }}
                onRowClick={(id) =>
                    props.modifyResident(
                        props.unitSubscriptionsList[id.row["id"]]
                    )
                }
                slots={{ toolbar: QuickSearchToolbar }}
                checkboxSelection
                onRowSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = rows.filter((row) =>
                        selectedIDs.has(row.id.toString())
                    );
                    props.getSelectedSubscribers(selectedRowData);
                }}
                disableRowSelectionOnClick={true}
            />
        </div>
    );
}
