import React, { Component } from "react";
import {
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    toast,
    MDBInput,
} from "mdbreact";
import axios from "../../../axios";
import SearchSubscribersTable from "../../../components/NetworkMgmt/Subscribers/Datatables/SearchSubscribersData";
import AddSubscriber from "../../../components/NetworkMgmt/Forms/AddSubscriber/AddSubscriberResubmit";
import ModifyResident from "../../../components/VenueMgmt/Forms/ModifyResident/ModifyResident";
import EndedSubscriptionInfo from "../../../components/VenueMgmt/Forms/ModifyResident/EndedSubscriptionInfo";
import $ from "jquery";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateStart: undefined,
            dateEnd: undefined,
            modalModifyResident: false,
            modalEndedSubscriptionInfo: false,
            subscriptionID: undefined,
            searchResults: undefined,
            StartDateTime: new Date(),
            EndDateTime: new Date(),
            vlan: undefined,
            setSearchBy: "email",
            // unitID_internal: this.props.unitID_internal
        };
        this.findSubscribers = this.findSubscribers.bind(this);
    }
    componentDidMount = () => {
        window.addEventListener("DOMContentLoaded", () => {
            window.querySelector(".newclass1").classList.add("custom-class");
        });
        this.props.getVenues();
    };
    componentDidUpdate = (prevProps) => {
        $(".subscribersTable")
            .find("table")
            .addClass("subscribersTableResults");
    };
    updateClassName = (searchResults) => {
        $(function () {
            var table = $(".MuiDataGrid-row");
            for (let i = 0; i < searchResults.length; i++) {
                if (searchResults.length !== 0) {
                    if (
                        new Date(
                            searchResults[i]["EndDateTimeUTC.ISO8601"]
                        ).getTime() < new Date().getTime()
                    ) {
                        $(table[i]).addClass("inactiveSubscription");
                    } else if (
                        searchResults[i]["SetupSuccessDateTimeUTC.ISO8601"] ===
                        ""
                    ) {
                        $(table[i]).addClass("inactiveSubscription");
                    } else {
                        $(table[i]).addClass("activeSubscription");
                    }
                }
            }
        });
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };
    findSubscribers() {
        // event.preventDefault();
        this.setState({
            loading: true,
            searchResults: undefined,
        });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: this.state.subscriberEmail,
            "PhoneNumber.E164": this.state.subscriberPhone,
            VenueID: this.props.venueID,
            UnitID_internal: this.state.unitID_internal,
        };
        axios
            .post(
                "/networkoperatorconsole/shoppingcart/listSubscriptions",
                body,
                {
                    headers: {},
                }
            )
            .then(
                (response) => {
                    this.setState({
                        searchResults: response.data.Subscriptions,
                    });
                    this.setState({ loading: false });
                    // this.updateClassName(response.data.Subscriptions);
                },
                (error) => {
                    this.setState({ loading: false });
                }
            );
    }
    getVenueDetails = (key, index, active) => {
        // console.log(index)
        this.setState({ loadingVenueDetails: true });
        if (index === undefined) {
            index = this.props.firstID;
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            UnitID_internal:
                index === undefined
                    ? sessionStorage.getItem("unitID_internalSubscriber")
                    : index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        // eslint-disable-next-line no-unused-vars
        let subscriptionID;
        this.setState({
            unitSubscriptionsList: undefined,
            SubscriberGivenName: undefined,
            SubscriberFamilyName: undefined,
            SubscriberEmail: undefined,
            SubscriberPhoneNumber: undefined,
            contactpref_email: undefined,
            contactpref_sms: undefined,
            SubscriberLocale: undefined,
            UnitFloor: undefined,
            UnitBuilding: undefined,
            SSID: undefined,
            StartDateTime: undefined,
            EndDateTime: undefined,
            UnitID: undefined,
            unitID_internal: undefined,
            servicePlanID: undefined,
            servicePlan: undefined,
            vlan: undefined,
            status: undefined,
            realpageSyncDate: undefined,
            subscriberManagedviaSSO: undefined,
            paymentFailed: undefined,
            StripeClientSecret: undefined,
            country: undefined,
            subscriptionDiscount: undefined,
            venueDiscount: undefined,
            externalSubscriberID: undefined,
            availablePlans: undefined,
            selectedServicePlanPrice: undefined,
            setupIntentID: undefined,
            active: active,
        });
        axios
            .post("/networkoperatorconsole/venues/listVenueUnitDetails", body, {
                headers: {},
            })
            .then((response) => {
                subscriptionID = Object.keys(
                    response.data["VenueUnits"][0]["Subscriptions"]
                );
                sessionStorage.setItem(
                    "venueID",
                    this.state.searchResults[key]["VenueID"]
                );
                // console.log(response.data["VenueUnits"][0]["Subscriptions"])
                this.setState({
                    unitSubscriptionsList: response.data["VenueUnits"][0],
                    subscriptionID: Object.keys(
                        response.data["VenueUnits"][0]["Subscriptions"]
                    ),
                    venueID: response.data["VenueUnits"][0]["Venue"]["VenueID"],
                });
                this.setState({ loadingVenueDetails: false });
                if (active === "Active") {
                    this.setState({
                        SubscriberGivenName:
                            this.state.searchResults[key].GivenName,
                        SubscriberFamilyName:
                            this.state.searchResults[key].FamilyName,
                        SubscriberEmail:
                            this.state.searchResults[key].EmailAddress,
                        SubscriberPhoneNumber:
                            this.state.searchResults[key]["PhoneNumber.E164"],
                        contactpref_email:
                            this.state.searchResults[key].contactpref_email,
                        contactpref_sms:
                            this.state.searchResults[key].contactpref_sms,
                        SubscriberLocale: this.state.searchResults[key].locale,
                        UnitFloor:
                            this.state.unitSubscriptionsList["UnitFloor"],
                        UnitBuilding:
                            this.state.unitSubscriptionsList["UnitBuilding"],
                        SSID: this.state.searchResults[key].ssid,
                        StartDateTime:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["StartDateTimeUTC.ISO8601"],
                        EndDateTime:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["EndDateTimeUTC.ISO8601"],
                        UnitID: this.state.unitSubscriptionsList["UnitID"],
                        unitID_internal:
                            this.state.unitSubscriptionsList["UnitID_internal"],
                        servicePlanID:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["ServicePlan"]["ServicePlanID"],
                        servicePlan:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["ServicePlan"]["ServicePlanName"],
                        vlan: this.state.unitSubscriptionsList["vlan"],
                        status: this.state.searchResults[key]
                            .AccountStatusAtCreation,
                        realpageSyncDate:
                            this.state.unitSubscriptionsList["Subscriptions"][
                                this.state.subscriptionID
                            ]["RealPageSyncDateTimeUTC.ISO8601"],
                        subscriberManagedviaSSO:
                            this.state.searchResults[key][
                                "SubscriberManagedviaSSO"
                            ],
                        paymentFailed:
                            this.state.searchResults[key]["paymentFailed"],
                        StripeClientSecret:
                            this.state.searchResults[key]["StripeClientSecret"],
                        country:
                            this.state.searchResults[key]["Country.ISO3166A3"],
                        subscriptionDiscount:
                            this.state.searchResults[key][
                                "VenueUnitPriceAdjustment"
                            ],
                        venueDiscount:
                            this.state.searchResults[key][
                                "VenuePriceAdjustment"
                            ],
                        externalSubscriberID:
                            this.state.searchResults[key][
                                "externalSubscriberID"
                            ],
                        availablePlans:
                            this.state.unitSubscriptionsList[
                                "AvailableServicePlans"
                            ],
                        selectedServicePlanPrice:
                            this.state.searchResults[key][
                                "ListBillingCyclePrice.USD"
                            ],
                        stripeClientParentSecret:
                            this.state.searchResults[key]["StripeClientSecret"],
                        setupIntentID:
                            this.state.searchResults[key]["SetupIntentId"],
                    });
                    this.props.toggleModifyResident(active);
                } else {
                    this.setState({
                        SubscriberGivenName:
                            this.state.searchResults[key].GivenName,
                        SubscriberFamilyName:
                            this.state.searchResults[key].FamilyName,
                        SubscriberEmail:
                            this.state.searchResults[key].EmailAddress,
                        SubscriberPhoneNumber:
                            this.state.searchResults[key]["PhoneNumber.E164"],
                        contactpref_email:
                            this.state.searchResults[key].contactpref_email,
                        contactpref_sms:
                            this.state.searchResults[key].contactpref_sms,
                        SubscriberLocale: this.state.searchResults[key].locale,
                        StartDateTime:
                            this.state.searchResults[key][
                                "StartDateTimeUTC.ISO8601"
                            ],
                        EndDateTime:
                            this.state.searchResults[key][
                                "EndDateTimeUTC.ISO8601"
                            ],
                        unitID_internal:
                            this.state.searchResults[key]["UnitID_internal"],
                        servicePlanID:
                            this.state.searchResults[key]["ServicePlanID"],
                        servicePlan:
                            this.state.searchResults[key]["ServicePlanName"],
                        // vlan: this.state.unitSubscriptionsList["vlan"],
                        // status: this.state.searchResults[key].AccountStatusAtCreation,
                        realpageSyncDate:
                            this.state.searchResults[key][
                                "RealPageSyncDateTimeUTC.ISO8601"
                            ],
                        // subscriberManagedviaSSO:
                        //   this.state.searchResults[key]["SubscriberManagedviaSSO"],
                        paymentFailed:
                            this.state.searchResults[key]["paymentFailed"],
                        StripeClientSecret:
                            this.state.searchResults[key]["StripeClientSecret"],
                        country:
                            this.state.searchResults[key]["Country.ISO3166A3"],
                        subscriptionDiscount:
                            this.state.searchResults[key][
                                "VenueUnitPriceAdjustment"
                            ],
                        venueDiscount:
                            this.state.searchResults[key][
                                "VenuePriceAdjustment"
                            ],
                        externalSubscriberID:
                            this.state.searchResults[key][
                                "externalSubscriberID"
                            ],
                        // availablePlans:
                        //   this.state.unitSubscriptionsList["AvailableServicePlans"],
                        selectedServicePlanPrice:
                            this.state.searchResults[key][
                                "ListBillingCyclePrice.USD"
                            ],
                        stripeClientParentSecret:
                            this.state.searchResults[key]["StripeClientSecret"],
                        setupIntentID:
                            this.state.searchResults[key]["SetupIntentId"],
                    });
                    this.props.toggleEndedSubscriptionInfo(active);
                }
            })
            .catch((error) => {
                this.setState({ error: true });
                this.setState({ loadingVenueDetails: false });
            });
    };
    handleSelectChangeUnit = (value) => {
        this.setState({
            selectedUnit: value,
            optionTemplateBuilding: [],
            selectedBuilding: undefined,
            selectedFloor: undefined,
            building: undefined,
            floor: undefined,
            updatedUnit: true,
        });
        let optionTemplateBuilding = [];
        this.setState({
            optionTemplateBuilding: [],
        });
        Object.keys(this.state.unitData[value]).map((oneKey, i) => {
            if (oneKey === "DEFAULT") {
                optionTemplateBuilding.push({ value: oneKey, name: oneKey });
            } else {
                optionTemplateBuilding.push({ value: oneKey, name: oneKey });
            }
            return true;
        });
        this.setState({
            optionTemplateBuilding: optionTemplateBuilding,
        });
    };
    handleSelectChangeBuilding = (value) => {
        if (value !== null) {
            this.setState({
                selectedBuilding: value,
            });
            let optionTemplateFloor = [];
            Object.keys(
                this.state.unitData[this.state.selectedUnit][value]
            ).map((oneKey, i) => {
                if (oneKey === "DEFAULT") {
                    optionTemplateFloor.push({ value: oneKey, name: oneKey });
                } else {
                    optionTemplateFloor.push({ value: oneKey, name: oneKey });
                }
                return true;
            });
            this.setState({
                optionTemplateFloor: optionTemplateFloor,
            });
        }
    };
    handleSelectChangeFloor = (value) => {
        if (value !== null) {
            this.setState({ selectedFloor: value });
            this.setState({
                unitID_internal:
                    this.state.unitData[this.state.selectedUnit][
                        this.state.selectedBuilding
                    ][value]["UnitID_internal"],
            });
        }
    };
    modifyResident = (key) => {};
    submitModify = (
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        contactpref_email,
        contactpref_sms,
        recentResidents,
        dateStart,
        oldDateStart,
        dateEnd,
        oldDateEnd,
        venueID,
        unitID,
        building,
        floor,
        servicePlanID,
        isUpdated,
        isUpdatedServicePlan,
        isUpdatedStartDate,
        isUpdatedEndDate,
        isUpdatedSubscriptionDiscount,
        unitID_internal,
        selectedServicePlanName,
        subscriberToken,
        updatedUnit,
        subscriptionDiscount,
        setupIntentID
    ) => {
        this.setState({ loading: true });
        let bodyDateStart = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": new Date(oldDateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z"),
            "NewStartDateTimeUTC.ISO8601": new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z"),
            UserTimeZone: new Date().getTimezoneOffset(),
            SubscriberToken: subscriberToken,
        };
        let bodyDateEnd = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
            "NewEndDateTimeUTC.ISO8601":
                dateEnd !== undefined
                    ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
                    : undefined,
            UserTimeZone: new Date().getTimezoneOffset(),
            SubscriberToken: subscriberToken,
        };
        let bodyServicePlan = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "CurrentStartDateTimeUTC.ISO8601": new Date(
                oldDateStart
            ).toISOString(),
            NewServicePlanID: servicePlanID,
            UserTimeZone: new Date().getTimezoneOffset(),
            SubscriberToken: subscriberToken,
        };
        let bodyUpdatePII = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            VenueID: venueID,
            SubscriberToken: subscriberToken,
        };
        let bodyUpdateUnit = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            StripeClientSecret: sessionStorage.getItem(
                "stripeClientSecretSubscriber"
            ),
            SetupIntentId: setupIntentID,
            NewUnitID_internal: unitID_internal,
            VenueID: venueID,
        };
        let bodyUpdateSubscriberPrice = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            StripeClientSecret: sessionStorage.getItem(
                "stripeClientSecretSubscriber"
            ),
            SetupIntentId: "",
            VenueID: venueID,
            NewVenueUnitPriceAdjustment: -Math.abs(subscriptionDiscount) * 100,
            "NewEndDateTimeUTC.ISO8601":
                dateEnd !== undefined
                    ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
                    : "",
        };
        Promise.all([
            new Date(dateStart) > new Date() && isUpdatedStartDate === true
                ? axios.post(
                      "/networkoperatorconsole/subscribers/resetSubscriptionStartDate",
                      bodyDateStart,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewVenueSubscribers.js:206 ~ ViewVenueSubscribers ~ No Date Start Change, No Date Start Change"
                  ),
            dateEnd !== undefined && isUpdatedEndDate === true
                ? axios.post(
                      "/networkoperatorconsole/subscribers/resetSubscriptionEndDate",
                      bodyDateEnd,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewVenueSubscribers.js:214 ~ ViewVenueSubscribers ~ bodyDateEnd",
                      bodyDateEnd
                  ),
            isUpdated === true
                ? axios.post(
                      "/networkoperatorconsole/subscribers/updateSubscriberPII",
                      bodyUpdatePII,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewVenueSubscribers.js:222 ~ ViewVenueSubscribers ~ No Change, No Change"
                  ),
            isUpdatedServicePlan === true
                ? axios.post(
                      "/networkoperatorconsole/subscribers/modifyServicePlan",
                      bodyServicePlan,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyServicePlan",
                      bodyServicePlan
                  ),
            updatedUnit === true
                ? axios.post(
                      "/networkoperatorconsole/subscribers/modifyVenueUnit",
                      bodyUpdateUnit,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateUnit",
                      bodyUpdateUnit
                  ),
            isUpdatedSubscriptionDiscount === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/modifySubscriptionPrice",
                      bodyUpdateSubscriberPrice,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateSubscriberPrice",
                      bodyUpdateSubscriberPrice
                  ),
        ])
            .then(async ([res1, res2, res3, res4, res5, res6]) => {
                toast.success("Updated Resident!", {
                    closeButton: false,
                });
                this.props.toggleModifyResident();
                this.setState({ activeData: undefined });
                this.props.findSubscribers();
                if (isUpdatedServicePlan === true) {
                    this.setState({
                        loading: false,
                        nextInvoiceAdjustedAmount:
                            res4.data["NextInvoiceAdjustedAmount.USD"] ===
                            undefined
                                ? ""
                                : res4.data["NextInvoiceAdjustedAmount.USD"] /
                                  100,
                        nextInvoiceDate:
                            res4.data["NextInvoiceDateTimeUTC.ISO8601"],
                        billingCycleAdjustment:
                            res4.data["BillingCyclePriceAdjustment"] ===
                            undefined
                                ? ""
                                : res4.data["BillingCyclePriceAdjustment"] /
                                  100,
                        subscriberName: firstName + " " + lastName,
                        subscriberEmail: email,
                        newServicePlanName: selectedServicePlanName,
                        servicePlanID: servicePlanID,
                        paymentFailed: res4.data["paymentFailed"],
                    });
                    sessionStorage.setItem(
                        "servicePlanIDSubscriber",
                        servicePlanID
                    );
                    // this.toggleModifyService();
                }
                this.setState({
                    loading: false,
                });
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.props.toggleModifyResident();
                this.setState({ recentResidents: recentResidents });
                this.setState({ loading: false });
            });
    };
    resubmitModify = (
        email,
        firstName,
        lastName,
        phoneNumber,
        selectedLanguage,
        venueID,
        optionTemplateUnits,
        optionTemplateBuilding,
        unitName,
        buildingName,
        floorName,
        selectedServicePlanID,
        unitID_internal
    ) => {
        this.props.handleSelectChangeVenue(venueID, unitName);
        this.setState({
            email: email,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            selectedLanguage: selectedLanguage,
            // venueID: venueID,
            optionTemplateUnits: optionTemplateUnits,
            optionTemplateBuilding: optionTemplateBuilding,
            selectedUnit: unitName,
            selectedBuilding: buildingName,
            selectedFloor: floorName,
            selectedServicePlanID: selectedServicePlanID,
            unitID_internal: unitID_internal,
        });
        this.props.toggleEndedSubscriptionInfo();
        this.props.toggleAddResident();
    };
    deleteResident = (
        email,
        venueID,
        unitID,
        building,
        floor,
        recentResidents,
        oldDateStart,
        unitID_internal,
        cancellationReason,
        refundAmount
    ) => {
        this.setState({ loadingDelete: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": oldDateStart,
            "NewEndDateTimeUTC.ISO8601": oldDateStart,
            endImmediately: true,
            UserTimeZone: new Date().getTimezoneOffset(),
            CancellationReasons: cancellationReason,
        };
        axios
            .post(
                "/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
                body,
                {
                    headers: {},
                }
            )
            .then((response) => {
                toast.success("Deleted Resident!", {
                    closeButton: false,
                });
                this.props.toggleModifyResident();
                // this.getVenueDetails(this.props.venueID, this.props.venueName);
                this.setState({ loadingDelete: false });
                this.props.findSubscribers();
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.setState({ error: true });
                this.setState({ loadingDelete: false });
            });
    };
    changeHandlerRadio = (nr) => () => {
        this.props.handleSelectChangeVenue();
        this.setState({
            setSearchBy: nr,
            subscriberPhone: undefined,
            subscriberEmail: undefined,
        });
    };
    changeHandlerPhone = (event) => {
        this.setState({ subscriberPhone: event });
    };
    render() {
        // console.log("Props.unitID_internal for SearchSubscribers: ", this.props.unitID_internal)
        // console.log("optionTemplateUnits State for SearchSubscribers: ", this.state.optionTemplateUnits)
        window.addEventListener("DOMcontentLoaded", () => {
            window.querySelector(".newclass1").classList.add("custom-class");
        });
        return (
            <>
                {this.props.paymentFailed && this.props.active === "false" ? (
                    <MDBModal
                        isOpen={this.props.modalAddPayment}
                        toggle={this.props.togglePayment}
                        size="md"
                        className="form"
                        centered
                    >
                        <MDBModalHeader toggle={this.props.togglePayment}>
                            Modify Billing Credit Card Info
                        </MDBModalHeader>
                        <MDBModalBody className="error">
                            <h4>
                                The subscriber's credit card info needs to be
                                updated for billing purposes.
                            </h4>
                            <MDBBtn
                                color="danger"
                                className="btn Ripple-parent btn-default centered upgradePlan"
                                href={
                                    "/update/checkout?ui=" +
                                    window.location.pathname.slice(1)
                                }
                            >
                                Modify Billing CC
                            </MDBBtn>
                        </MDBModalBody>
                    </MDBModal>
                ) : (
                    ""
                )}
                {this.props.loadingVenueDetails === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <MDBModal
                            isOpen={this.props.modalModifyResident}
                            toggle={this.toggle}
                            size="fluid"
                            className="form"
                        >
                            <ModifyResident
                                toggleModifyResident={
                                    this.props.toggleModifyResident
                                }
                                firstName={this.props.SubscriberGivenName}
                                lastName={this.props.SubscriberFamilyName}
                                email={this.props.SubscriberEmail}
                                phoneNumber={this.props.SubscriberPhoneNumber}
                                receivetext={this.props.contactpref_email}
                                receivephone={this.props.contactpref_sms}
                                contactpref_sms={this.props.contactpref_sms}
                                contactpref_email={this.props.contactpref_email}
                                language={this.props.SubscriberLocale}
                                floor={this.props.UnitFloor}
                                building={this.props.UnitBuilding}
                                startDate={this.props.StartDateTime}
                                endDate={this.props.EndDateTime}
                                unitID={this.props.UnitID}
                                submitModify={this.submitModify}
                                submitDelete={this.deleteResident}
                                venueID={this.props.venueID}
                                loading={this.props.loading}
                                SSID={this.props.SSID}
                                unitID_internal={this.props.unitID_internal}
                                servicePlanID={this.props.servicePlanID}
                                servicePlan={this.props.servicePlan}
                                availablePlans={this.props.availablePlans}
                                // getVenueDetails={this.getVenueDetails}
                                vlan={this.props.vlan}
                                status={this.props.status}
                                realpageSyncDate={this.props.realpageSyncDate}
                                subscriberManagedviaSSO={
                                    this.props.subscriberManagedviaSSO
                                }
                                paymentFailed={this.props.paymentFailed}
                                StripeClientSecret={
                                    this.props.StripeClientSecret
                                }
                                country={this.props.country}
                                subscriptionDiscount={
                                    this.props.subscriptionDiscount
                                }
                                venueDiscount={this.props.venueDiscount}
                                externalSubscriberID={
                                    this.props.externalSubscriberID
                                }
                                selectedServicePlanPrice={
                                    this.props.selectedServicePlanPrice
                                }
                                stripeClientParentSecret={
                                    this.props.stripeClientParentSecret
                                }
                                setupIntentID={this.props.setupIntentID}
                                showUpdate={this.props.showUpdate}
                                cancelInputType={this.props.cancelInputType}
                                updateName={this.props.updateName}
                            />
                        </MDBModal>
                        <MDBModal
                            isOpen={this.props.modalAddNewSubscriberResubmit}
                            toggle={this.props.toggleAddResidentResubmit}
                            size="fluid"
                            className="form"
                        >
                            <AddSubscriber
                                toggleAddNewSubscriber={
                                    this.props.toggleAddResidentResubmit
                                }
                                getVenues={this.props.getVenues}
                                firstName={this.props.SubscriberGivenName}
                                lastName={this.props.SubscriberFamilyName}
                                email={this.props.SubscriberEmail}
                                phoneNumber={this.props.SubscriberPhoneNumber}
                                selectedLanguage={this.props.SubscriberLocale}
                                optionTemplateVenues={
                                    this.props.optionTemplateVenues
                                }
                                optionTemplateUnits={
                                    this.props.optionTemplateUnits
                                }
                                optionTemplateBuilding={
                                    this.props.optionTemplateBuilding
                                }
                                optionTemplateFloor={
                                    this.props.optionTemplateFloor
                                }
                                clearDropDown={false}
                                venueID={this.props.venueID}
                                handleSelectChangeUnit={
                                    this.props.handleSelectChangeUnit
                                }
                                handleSelectChangeUnitInitial={
                                    this.props.handleSelectChangeUnitInitial
                                }
                                handleSelectChangeBuilding={
                                    this.props.handleSelectChangeBuilding
                                }
                                handleSelectChangeFloor={
                                    this.props.handleSelectChangeFloor
                                }
                                selectedUnit={this.props.selectedUnit}
                                selectedBuilding={this.props.selectedBuilding}
                                selectedFloor={this.props.selectedFloor}
                                handleSelectChangeVenue={
                                    this.props.handleSelectChangeVenue
                                }
                                // handleSelectChangeBuilding={this.props.handleSelectChangeBuilding}
                                fromEndedSubscription={true}
                                getVenueDetails={this.props.getVenueDetails}
                                submitHandler={this.props.submitHandler}
                                unitID_internal={this.props.unitID_internal}
                                servicePlanID={this.props.servicePlanID}
                                servicePlan={this.props.servicePlan}
                                availablePlans={this.props.availablePlans}
                                optionTemplateServicePlans={
                                    this.props.optionTemplateServicePlans
                                }
                                selectedServicePlanID={
                                    this.props.selectedServicePlanID
                                }
                                handleSelectChangeServicePlan={
                                    this.props.handleSelectChangeServicePlan
                                }
                                venueUnitData={this.props.venueUnitData}
                                unitData={this.props.unitData}
                                loadingRecreateSubscription={
                                    this.props.loadingRecreateSubscription
                                }
                                paymentFailed={this.props.paymentFailed}
                                togglePayment={this.props.togglePayment}
                                modalAddPayment={this.props.modalAddPayment}
                            />
                        </MDBModal>
                        <MDBModal
                            isOpen={this.props.modalEndedSubscriptionInfo}
                            toggle={this.toggle}
                            size="fluid"
                            className="form"
                        >
                            <EndedSubscriptionInfo
                                toggleEndedSubscriptionInfo={
                                    this.props.toggleEndedSubscriptionInfo
                                }
                                venueName={this.props.venueName}
                                firstName={this.props.SubscriberGivenName}
                                lastName={this.props.SubscriberFamilyName}
                                email={this.props.SubscriberEmail}
                                phoneNumber={this.props.SubscriberPhoneNumber}
                                receivetext={this.props.contactpref_email}
                                receivephone={this.props.contactpref_sms}
                                contactpref_sms={this.props.contactpref_sms}
                                contactpref_email={this.props.contactpref_email}
                                language={this.props.SubscriberLocale}
                                floor={this.props.UnitFloor}
                                building={this.props.UnitBuilding}
                                startDate={this.props.StartDateTime}
                                endDate={this.props.EndDateTime}
                                unitID={this.props.UnitID}
                                submitModify={this.submitModify}
                                submitDelete={this.deleteResident}
                                venueID={this.props.venueID}
                                loading={this.props.loading}
                                SSID={this.props.SSID}
                                unitID_internal={this.props.unitID_internal}
                                servicePlanID={this.props.servicePlanID}
                                servicePlan={this.props.servicePlan}
                                availablePlans={this.props.availablePlans}
                                // getVenueDetails={this.getVenueDetails}
                                vlan={this.props.vlan}
                                status={this.props.status}
                                realpageSyncDate={this.props.realpageSyncDate}
                                subscriberManagedviaSSO={
                                    this.props.subscriberManagedviaSSO
                                }
                                paymentFailed={this.props.paymentFailed}
                                StripeClientSecret={
                                    this.props.StripeClientSecret
                                }
                                country={this.props.country}
                                subscriptionDiscount={
                                    this.props.subscriptionDiscount
                                }
                                venueDiscount={this.props.venueDiscount}
                                externalSubscriberID={
                                    this.props.externalSubscriberID
                                }
                                selectedServicePlanPrice={
                                    this.props.selectedServicePlanPrice
                                }
                                stripeClientParentSecret={
                                    this.props.stripeClientParentSecret
                                }
                                setupIntentID={this.props.setupIntentID}
                                showUpdate={this.props.showUpdate}
                                cancelInputType={this.props.cancelInputType}
                                searchResults={this.state.searchResults}
                                active={this.state.active}
                                resubmitModify={this.props.resubmitModify}
                                getVenueDetails={this.props.getVenueDetails}
                                venueUnitData={this.props.venueUnitData}
                            />
                        </MDBModal>
                    </>
                )}
                <MDBModalHeader toggle={this.props.toggleSearchSubscriber}>
                    <h4>Search for Subscriber</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="form-check-inline">
                        <MDBInput
                            label="Email"
                            type="radio"
                            id="setSearchByEmail"
                            name="Email"
                            onChange={this.props.changeHandlerRadio("email")}
                            checked={
                                this.props.setSearchBy === "email"
                                    ? true
                                    : false
                            }
                            // className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"
                        />
                    </div>
                    <div className="form-check form-check-inline">
                        <MDBInput
                            label="Phone Number"
                            type="radio"
                            id="setSearchByPhone"
                            name="Phone"
                            onChange={this.props.changeHandlerRadio("phone")}
                            checked={
                                this.props.setSearchBy === "phone"
                                    ? true
                                    : false
                            }
                        />
                    </div>
                    <div className="form-check form-check-inline">
                        <MDBInput
                            label="Venue/Unit"
                            type="radio"
                            id="setSearchByVenue"
                            name="Venue"
                            onChange={this.props.changeHandlerRadio("venue")}
                            checked={
                                this.props.setSearchBy === "venue"
                                    ? true
                                    : false
                            }
                        />
                    </div>
                    {/* <form className="needs-validation" onSubmit={this.findSubscribers} noValidate> */}
                    {this.props.setSearchBy === "email" ||
                    this.props.setSearchBy === undefined ? (
                        <MDBCol sm="12" lg="6" className="pl-0">
                            <input
                                required
                                name="subscriberEmail"
                                id="subscriberEmail"
                                className="form-control"
                                onChange={this.props.changeHandler}
                                value={this.props.subscriberEmail}
                                placeholder="Enter Email Address"
                            />
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Note: Only exact matches will be found
                            </label>
                        </MDBCol>
                    ) : (
                        ""
                    )}
                    {this.props.setSearchBy === "phone" ? (
                        <MDBCol sm="12" lg="6" className="pl-0">
                            <PhoneInput
                                placeholder="Enter Phone Number"
                                value={this.props.subscriberPhone}
                                onChange={this.props.changeHandlerPhone}
                                name="subscriberPhone"
                                id="subscriberPhone"
                                className={
                                    this.state.loading === true
                                        ? "form-control loading"
                                        : "form-control"
                                }
                                defaultCountry="US"
                            />
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Note: Only exact matches will be found
                            </label>
                        </MDBCol>
                    ) : (
                        ""
                    )}
                    <br />
                    {this.props.setSearchBy === "venue" ? (
                        <>
                            <MDBRow>
                                <MDBCol sm="12" lg="3" className="">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Venue
                                    </label>
                                    <SelectSearch
                                        id="selectVenue"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={
                                            this.props.optionTemplateVenues
                                        }
                                        isOptionSelected={true}
                                        inputId="venueArray"
                                        classNamePrefix="venue-select"
                                        placeholder="Select..."
                                        onChange={
                                            this.props.handleSelectChangeVenue
                                        }
                                        value={
                                            this.props.clearDropDown === true
                                                ? ""
                                                : this.props.venueID
                                        }
                                    />
                                </MDBCol>
                                {/* {this.props.attributeForm} */}
                                <MDBCol sm="3" lg="3" className="">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Unit
                                    </label>
                                    <SelectSearch
                                        id="selectUnit"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.props.optionTemplateUnits}
                                        isOptionSelected={true}
                                        inputId="venueArray"
                                        classNamePrefix="venue-select"
                                        placeholder="Select..."
                                        onChange={
                                            this.props.handleSelectChangeUnit
                                        }
                                        value={
                                            this.props.clearDropDown === true
                                                ? ""
                                                : this.props.selectedUnit
                                        }
                                    />
                                </MDBCol>
                                <MDBCol sm="3" lg="3" className="">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Building
                                    </label>
                                    <SelectSearch
                                        id="selectUnit"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={
                                            this.props.optionTemplateBuilding
                                        }
                                        isOptionSelected={true}
                                        inputId="venueArray"
                                        classNamePrefix="venue-select"
                                        placeholder="Select..."
                                        onChange={
                                            this.props
                                                .handleSelectChangeBuilding
                                        }
                                        value={
                                            this.props.clearDropDown === true
                                                ? ""
                                                : this.props.selectedBuilding
                                        }
                                    />
                                </MDBCol>
                                <MDBCol sm="3" lg="3" className="">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Floor
                                    </label>
                                    <SelectSearch
                                        id="selectUnit"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.props.optionTemplateFloor}
                                        isOptionSelected={true}
                                        inputId="venueArray"
                                        classNamePrefix="venue-select"
                                        placeholder="Select..."
                                        onChange={
                                            this.props.handleSelectChangeFloor
                                        }
                                        value={
                                            this.props.clearDropDown === true
                                                ? ""
                                                : this.props.selectedFloor
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Note: If searching by Unit; Building and Floor
                                must be populated for search to work
                            </label>
                            <br />
                        </>
                    ) : (
                        ""
                    )}

                    {this.state.searchResults !== undefined ? (
                        <>
                            {this.state.loading === true ? (
                                <div
                                    className="overlay"
                                    id="overlay"
                                    style={{ display: "block" }}
                                >
                                    <div className="overlayMessage">
                                        <div
                                            className="spinner-border spinner-border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <SearchSubscribersTable
                                        className="subscribersSearch allrecentresidentsExpanded"
                                        searchResults={this.state.searchResults}
                                        modifyResident={this.modifyResident}
                                        getVenueUnitDetails={
                                            this.props.getVenueUnitDetails
                                        }
                                    />
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Note: Only active subscriptions can be
                                        viewed
                                    </label>
                                </>
                            )}
                        </>
                    ) : this.state.loading === true ? (
                        <div
                            className="overlay"
                            id="overlay"
                            style={{ display: "block" }}
                        >
                            <div className="overlayMessage">
                                <div
                                    className="spinner-border spinner-border"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <SearchSubscribersTable
                            className="subscribersSearch allrecentresidentsExpanded"
                            searchResults={this.props.searchResults}
                            modifyResident={this.modifyResident}
                            getVenueUnitDetails={this.props.getVenueUnitDetails}
                        />
                    )}
                    <div className="text-right submitButton pt-2">
                        <MDBBtn
                            color="danger"
                            type="submit"
                            onClick={this.props.findSubscribers}
                        >
                            {this.props.loadingSearchResults === true ? (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                "Search"
                            )}
                        </MDBBtn>
                    </div>
                    {/* </form> */}
                </MDBModalBody>
            </>
        );
    }
}
export default Transactions;
